* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	margin: 0;
}

p {
	margin: 0;
}

button,
input,
a {
	font-family: 'Poppins', sans-serif;
}

button {
	background: none;
	border: none;
	padding: 0;
}

a {
	text-decoration: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

button,
iframe {
	background: none;
	border: none;
}

@media screen and (max-width: 1000px) {
	html {
		font-size: 85%;
	}
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #17181c;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
